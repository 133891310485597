<template>
  <label :class="classes">
    <span v-if="props.label" class="label">{{ props.label }}</span>

    <span class="input-row">
      <span v-if="props.placeholder" class="placeholder">{{ props.placeholder }}</span>
      <input
        ref="inputRef"
        class="field"
        :inputmode="props.inputmode || 'text'"
        :type="props.type || 'text'"
        :name="props.name"
        :value="props.value"
        :readonly="props.isDisabled"
        :required="props.isRequired"
        :autocomplete="props.autocomplete || 'on'"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @keyup.enter="emit('submit', $event)"
      />
    </span>

    <atomic-hint v-if="props.hint" v-bind="props.hint" />
  </label>
</template>

<script setup lang="ts">
  const props = defineProps<{
    type?: string;
    name: string;
    value?: string;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    hint?: { variant: string; message: string };
    autocomplete?: string;
    inputmode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  }>();
  const emit = defineEmits(['blur', 'focus', 'input', 'update:value', 'submit']);

  const state = reactive({
    hasFocus: false,
  });

  const classes = computed(() => [
    'input-text',
    { 'is-disabled': props.isDisabled },
    { 'is-hidden': props.type === 'hidden' },
    { 'has-value': props.value },
    { 'has-focus': state.hasFocus },
    { 'has-error': props.hint?.variant === 'error' },
  ]);

  const onBlur = (e: any) => {
    emit('blur', e.target.value);
    state.hasFocus = false;
  };
  const onFocus = (e: any) => {
    emit('focus', e.target.value);
    state.hasFocus = true;
  };

  const onInput = (e: any) => {
    emit('input', e.target.value);
    emit('update:value', e.target.value);
  };

  const inputRef = ref();

  const focusField = (): void => {
    inputRef.value.focus();
  };

  defineExpose({
    focusField,
  });
</script>

<style src="~/assets/styles/components/form/input/text.scss" lang="scss" />
